<template>
  <v-card
    :class="`${proposalOk ? 'border-green ' : ''} border-radius`"
    outlined
  >
    <v-container
      class="d-flex"
    >
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          cols="2"
          md="3"
          ls="2"
        >
          <v-card-actions
            class="pa-0 justify-center text-center"
          >
            <v-btn
              height="55px"
              width="55px"
              :class="`bottom-details ${proposalOk ? 'secondary' : 'secondary'}`"
              :disabled="!proposalOk"
              @click="detailsFinished"
            >
              <v-icon
                :color="'primary'"
                size="36px"
                light
              >
                {{ proposalOk && tenderClosed ? 'fal fa-folder-open' : `${proposalOk ? 'fal fa-folder' : 'far fa-folder-minus'}` }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <h5>{{ $t('name') }}</h5>
              <v-list-item-subtitle>{{ projectInfo.proponentBusinessName }}</v-list-item-subtitle>
            </v-col>
            <v-col v-if="this.userInfo.profileCode && this.userInfo.profileCode !== 'SU'">
              <h5>{{ $t('presentation_date') }}</h5>
              <span :class="!existDateTimeFinished ? 'grey--text' : ''">
                {{ existDateTimeFinished ? $_formatDateMixin_formatDate(projectInfo.proposalFinishedDate) : $t('unfinished_proposal') }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h5>{{ $t('uploads_files') }}</h5>
              <span>{{ projectInfo.uploadedFile }} de {{ projectInfo.totalFile }} </span>
            </v-col>
            <v-col v-if="this.userInfo.profileCode && this.userInfo.profileCode !== 'SU'">
              <h5>{{ $t('presentation_time') }}</h5>
              <span :class="!existDateTimeFinished ? 'grey--text' : ''">
                {{ existDateTimeFinished ? $_formatDateMixin_formatTime(projectInfo.proposalFinishedDate) : $t('unfinished_proposal') }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import FormatDateMixin from '@/mixins/formatDateMixin'

export default {
  name: 'CardProject',
  mixins: [FormatDateMixin],
  data () {
    return {
      userInfo: this.$store.state.security.userInfo
    }
  },
  props: {
    projectInfo: {
      type: Object,
      required: true,
      description: 'Objeto con la informacion del proyecto cerrado'
    },
    tenderClosed: {
      type: Boolean,
      required: true,
      description: 'Objeto con la informacion del proyecto cerrado'
    }
  },
  computed: {
    proposalOk () {
      return this.projectInfo.proposalTenderStatusEnum === 'FINISHED'
    },
    existDateTimeFinished () {
      return this.projectInfo.proposalFinishedDate !== 0
    }
  },
  methods: {
    detailsFinished () {
      if (this.tenderClosed) {
        this.$router.push({ name: 'ProjectFinishDetail', params: { proposalId: this.projectInfo.proposalId, tenderId: this.$route.params.tenderId } })
      }
    }
  }
}
</script>
<style lang="scss">
.bottom-details {
  border-radius: 15%;
}
.p-none {
  padding: 0 !important;
}
.border-green {
  border: 3px solid #76CC14 !important;
}
.border-radius {
  border-radius: 10px !important;
}
</style>
<i18n>
{
  "es": {
    "presentation_date": "FECHA PRESENTACIÓN PROPUESTA",
    "presentation_time": "HORA PRESENTACIÓN PROPUESTA",
    "uploads_files": "ARCHIVOS CARGADOS",
    "name": "NOMBRE",
    "unfinished_proposal": "SIN FINALIZAR"
  }
}
</i18n>
