<template>
  <v-container>
    <v-row class="pt-7">
      <v-col class="d-flex">
        <h1 class="headline font-weight-bold text-uppercase mt-auto">
          {{ projectDetails.tenderName }}
        </h1>
      </v-col>
      <v-col
        v-if="Object.keys(projectDetails).lenght !== 0"
        cols="4"
        align="right"
      >
        <time-remaining
          ref="timeRemaining"
          :end-time="projectDetails.tenderDueDate"
        />
      </v-col>
    </v-row>
    <v-divider class="my-3 primary" />
    <v-row no-gutters>
      <v-col>
        <h2 class="headline text-uppercase grey--text">
          {{ $t('basic_data') }}
        </h2>
        <v-divider class="my-3" />
      </v-col>
    </v-row>
    <v-row class="my-6">
      <v-col
        class="d-lg-flex py-0"
        cols="12"
        lg="8"
      >
        <v-container class="py-0">
          <v-row class="mb-3">
            <span class="font-weight-bold text-uppercase">
              {{ projectDetails.tenderName }}
            </span>
          </v-row>
          <v-row class="mb-3">
            <span class="">
              {{ $t('contractor') }}:
            </span>
            <span
              class="ml-lg-2 grey--text"
            >
              {{ projectDetails.tenderOwner }}
            </span>
          </v-row>
          <v-row class="mb-3">
            <div class="grey--text">
              {{ projectDetails.tenderdescription }}
            </div>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        class="d-lg-flex py-0"
        cols="12"
        lg="4"
      >
        <v-container class="py-0">
          <v-row class="pa-0">
            <v-col class="py-0 col-12 col-md-6 col-lg-12">
              <v-row class="mb-2">
                <span>
                  {{ $t('term') }}:
                </span>
                <span class="ml-lg-2 grey--text">
                  {{ projectDetails.contractTerm }}
                </span>
              </v-row>
              <v-row class="mb-2">
                <span>
                  {{ $t('process_number') }}:
                </span>
                <span class="ml-lg-2 grey--text">
                  {{ projectDetails.processNumber }}
                </span>
              </v-row>
            </v-col>
            <v-col class="py-0 col-12 col-md-6 col-lg-12">
              <v-row class="mb-2">
                <span>
                  {{ $t('close_date') }}:
                </span>
                <span class="grey--text ml-3">
                  {{ $_formatDateMixin_formatDate(projectDetails.tenderDueDate) }}
                </span>
              </v-row>
              <v-row class="mb-2">
                <span>
                  {{ $t('close_time') }}:
                </span>
                <span class="grey--text ml-3">
                  {{ $_formatDateMixin_formatTime(projectDetails.tenderDueDate) }}
                </span>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <h2 class="headline text-uppercase grey--text">
          {{ $t('proposal_received') }}
        </h2>
        <v-divider class="my-3" />
      </v-col>
    </v-row>
    <v-container fluid>
      <v-row>
        <v-col
          v-for="projects in projectDetails.finishedProposalDetailResponseList"
          :key="projects.proposalId"
          class="d-flex"
          cols="12"
          md="6"
          lg="6"
        >
          <card-close-processes
            class="d-flex flex-column align-stretch col-12"
            :project-info="projects"
            :tender-closed="$refs.timeRemaining.outTime"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-row class="d-flex justify-end">
      <v-btn
        color="grey lighten-1 white--text"
        class="mt-12 mb-4 mr-4"
        @click="cancel"
      >
        {{ $t('cancel') }}
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import CardCloseProcesses from '@/components/uiComponents/cards/cardCloseProcess/CardCloseProcesses'
import { GET_DETAILS_PROJECTS_RECEIVED } from './data/projectReceived'
import { ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST } from '@/store/mutations-types'
import handleErrorMixin from '@/mixins/handleErrorMixin'
import store from '@/store'
import Vue from 'vue'
import TimeRemaining from '@/components/uiComponents/timeRemaining/TimeRemaining'
import FormatDateMixin from '@/mixins/formatDateMixin'

export default {
  name: 'ProjectsDetail',
  components: {
    CardCloseProcesses,
    TimeRemaining
  },
  mixins: [
    handleErrorMixin,
    FormatDateMixin
  ],
  beforeRouteEnter (to, from, next) {
    if (!to.params.tenderId) {
      next({ name: 'projects' })
    } else {
      store.commit(ADD_COUNTER_REQUEST)
      let params = {
        params: {
          tenderId: parseInt(to.params.tenderId)
        }
      }
      Vue.axios
        .get(`${GET_DETAILS_PROJECTS_RECEIVED}`, params)
        .then(response => {
          store.commit(SUBTRACT_COUNTER_REQUEST)
          next(vm => vm.setData(response.data))
        })
        .catch(error => {
          if (error.response) {
            store.commit(SUBTRACT_COUNTER_REQUEST)
            next(vm => vm.$_handleErrorMixin_generateError(error))
          }
        })
    }
  },
  data () {
    return {
      projectDetails: {}
    }
  },
  methods: {
    sortProposal (p1, p2) {
      if (p1.proposalFinishedDate !== 0) {
        return p2.proposalFinishedDate !== 0 ? p1.proposalFinishedDate - p2.proposalFinishedDate : -1
      } else if (p2.proposalFinishedDate !== 0) {
        return 1
      }

      let valueP1 = p1.totalFile === p1.uploadedFile ? -1 : 1
      let valueP2 = p2.totalFile === p2.uploadedFile ? -1 : 1
      return valueP1 - valueP2
    },
    cancel () {
      this.$router.go(-1)
    },
    setData (data) {
      this.projectDetails = data
      this.projectDetails.finishedProposalDetailResponseList.sort(this.sortProposal)
    }
  }
}
</script>
<style lang="scss">
.border_radius {
  border-radius: 15px solid green !important
}
</style>
<i18n>
{
  "es": {
    "basic_data": "DATOS BÁSICOS DEL PROYECTO",
    "contractor": "CONTRATANTE",
    "members": "INTEGRANTES",
    "process_number":"NÚMERO DE PROCESO",
    "term": "PLAZO",
    "close_date": "FECHA DE VISUALIZACIÓN",
    "close_time": "HORA DE VISUALIZACIÓN",
    "proposal_received":"PROPUESTAS RECIBIDAS",
    "cancel": "SALIR"
  }
}
</i18n>
