<template>
  <div class="time-remaining-container">
    <v-row>
      <v-col class="d-flex col-4 pa-0">
        <v-row
          align="center"
          justify="center"
        >
          <v-icon
            class="d-flex"
            :color="outTime ? 'blue-grey lighten-4' : 'grey'"
            size="3.8em"
            light
          >
            {{ `${outTime ? 'fa fa-minus-circle' : 'fal fa-clock' }` }}
          </v-icon>
        </v-row>
      </v-col>
      <v-col class="pa-0 pl-2">
        <v-row
          class="title-time-remaining grey--text"
        >
          {{ $t('title') }}
        </v-row>
        <v-row>
          <v-col class="pa-0 pr-1 col-3">
            <v-row
              class="number pt-3"
              justify="center"
            >
              {{ days }}
            </v-row>
            <v-row
              class="label grey--text"
              justify="center"
            >
              {{ $t('labels.days') }}
            </v-row>
          </v-col>
          <v-col class="pa-0 pr-1">
            <v-row
              class="number pt-3"
              justify="center"
            >
              {{ hours }}
            </v-row>
            <v-row
              class="label grey--text"
              justify="center"
            >
              {{ $t('labels.hours') }}
            </v-row>
          </v-col>
          <span class="number">:</span>
          <v-col class="pa-0 pr-1">
            <v-row
              class="number  pt-3"
              justify="center"
            >
              {{ minutes }}
            </v-row>
            <v-row
              class="label grey--text"
              justify="center"
            >
              {{ $t('labels.minutes') }}
            </v-row>
          </v-col>
          <span class="number">:</span>
          <v-col class="pa-0 pr-1">
            <v-row
              class="number pt-3"
              justify="center"
            >
              {{ seconds }}
            </v-row>
            <v-row
              class="label grey--text"
              justify="center"
            >
              {{ $t('labels.seconds') }}
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment-timezone'

/**
 *
 * @author Juan P. Bolivar
 * @since 18/05/2020
 * @version 1.0.0
 */
export default {
  name: 'TimeRemaining',
  props: {
    endTime: {
      type: Number,
      default: 0,
      description: 'Instante limite de tiempo'
    }
  },
  data () {
    return {
      timeNow: null,
      intervalUpdate: null,
      timeZoneColombia: process.env.VUE_APP_TIME_ZONE
    }
  },
  computed: {
    limitTime () {
      return parseInt(moment.tz(this.endTime * 1000, this.timeZoneColombia).format('x'))
    },
    timeRemaining () {
      return this.limitTime - this.timeNow
    },
    days () {
      return this.formatValue(Math.trunc(this.timeRemaining / 86400000))
    },
    hours () {
      let remaining = this.timeRemaining - (this.days * 86400000)
      return this.formatValue(Math.trunc(remaining / 3600000))
    },
    minutes () {
      let remaining = this.timeRemaining - ((this.days * 86400000) + (this.hours * 3600000))
      return this.formatValue(Math.trunc(remaining / 60000))
    },
    seconds () {
      let remaining = this.timeRemaining - ((this.days * 86400000) + (this.hours * 3600000) + (this.minutes * 60000))
      return this.formatValue(Math.trunc(remaining / 1000))
    },
    outTime () {
      if ((this.days === '00') && (this.hours === '00') && (this.minutes === '00') && (this.seconds === '00')) {
        return true
      }
      return false
    }
  },
  watch: {
    outTime (value) {
      if (!value && this.timeNow && this.endTime !== 0) {
        this.active(false)
      }
    },
    endTime (value) {
      if (value !== 0) {
        this.active(true)
      }
    }
  },
  created () {
    this.updateTimeNow()
  },
  beforeDestroy () {
    this.active(false)
  },
  methods: {
    /**
     *
     * @author Juan P. Bolivar
     * @since 18/05/2020
     * @version 1.0.0
     */
    updateTimeNow () {
      this.timeNow = parseInt(moment().clone().tz(this.timeZoneColombia).format('x'))
    },
    /**
     *
     * @author Juan P. Bolivar
     * @since 18/05/2020
     * @version 1.0.0
     */
    formatValue (value) {
      if (value < 0) {
        value = 0
      }
      if ((value + '').length === 1) {
        return '0' + value
      }
      return value
    },
    /**
     *
     * @author Juan P. Bolivar
     * @since 18/05/2020
     * @version 1.0.0
     */
    active (enable) {
      if (enable) {
        this.intervalUpdate = setInterval(this.updateTimeNow, 1000)
      } else {
        clearInterval(this.intervalUpdate)
      }
    }
  }
}
</script>

<i18n>
{
  "es": {
    "title": "tiempo restante",
    "labels": {
      "days": "dias",
      "hours": "horas",
      "minutes": "minutos",
      "seconds": "segundos"
    }
  }
}
</i18n>

<style lang="scss" scoped>
.time-remaining-container {
  min-width: 189px;
  max-width: 189px;
  transform: translateX(-24px);
}
.title-time-remaining {
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 3px;
}
.label {
  font-family: 'PT Sans', sans-serif;
  font-size: 10px;
  font-weight: 620;
  padding: 0 10px;
}
.number {
  color: #dd1212;
  font-weight: 900;
}
</style>
